import React from 'react';
import PropTypes from 'prop-types';
import classes from '../../assets/css/SearchBar.module.css';

const SearchBar = ({ onSearch }) => (
  <>
    <div className={classes.SearchBarContainer}>
      <div>
        <p>Select the city and the amount of users</p>
      </div>
    </div>
    <div className={classes.SearchBar}>
      <label>
        <input
          aria-label="Search licences"
          placeholder="Search licences"
          name="searchBar"
          type="text"
          onChange={onSearch}
        />
        <i className="fas fa-search" />
      </label>
    </div>
  </>
);

SearchBar.propTypes = {
  onSearch: PropTypes.func.isRequired,
};

export default SearchBar;
