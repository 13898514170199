import React from 'react';
import PropTypes from 'prop-types';
import { Router, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import classes from '../assets/css/App.module.css';
import { initLicences, setLicenceLength } from '../actions';
import history from '../history';
import ScrollToTop from '../components/ScrollToTop';
import Navbar from '../components/Navbar/Navbar';
import ChooseLicence from './ChooseLicence/ChooseLicence';
import CreateAccount from './CreateAccount/CreateAccount';
import SignIn from './SignIn/SignIn';
import ConfirmDetails from './Payment/ConfirmDetails';
import Purchase from './Payment/Purchase';
import Download from './Download/Download';
import ConditionRoute from '../components/Route/ConditionRoute';
import EditBasket from './EditBasket/EditBasket';
import SetPassword from './CreateAccount/SetPassword';
import Footer from '../components/Footer';
import Prefooter from '../components/Footer/Prefooter';

class App extends React.Component {
  componentDidMount() {
    const { setLicenceLength, initLicences } = this.props;
    setTimeout(() => {
      initLicences();
    });

    if (
      window.location.search !== '' &&
      (window.location.search.split('=')[1] === 'monthly' ||
        window.location.search.split('=')[1] === 'annual')
    ) {
      setLicenceLength(window.location.search.split('=')[1]);
    }
  }

  render() {
    const { selectedLicences, token, paidOrder } = this.props;
    return (
      <div className={classes.App}>
        <div className={classes.AppContainer}>
          <Router history={history}>
            <ScrollToTop>
              <Navbar />
              <div className={classes.Content}>
                <ConditionRoute
                  path="/editPurchase/:id"
                  exact
                  condition={!paidOrder}
                  component={EditBasket}
                />
                <Route
                  path="/create-password/:id"
                  exact
                  component={SetPassword}
                />
                <ConditionRoute
                  path="/"
                  exact
                  condition={!selectedLicences}
                  component={ChooseLicence}
                />
                <ConditionRoute
                  path="/create-account"
                  exact
                  condition={!token}
                  component={CreateAccount}
                />
                <ConditionRoute
                  path="/confirm-details"
                  exact
                  condition={!paidOrder}
                  component={ConfirmDetails}
                />
                <Route path="/purchase" exact component={Purchase} />
                <Route path="/download" exact component={Download} />
                <Route path="/sign-in" exact component={SignIn} />
              </div>
            </ScrollToTop>
          </Router>
        </div>
        <Prefooter />
        <Footer />
      </div>
    );
  }
}

App.propTypes = {
  initLicences: PropTypes.func.isRequired,
  setLicenceLength: PropTypes.func,
  selectedLicences: PropTypes.bool,
  token: PropTypes.bool,
  paidOrder: PropTypes.bool,
};

App.defaultProps = {
  selectedLicences: false,
  paidOrder: false,
  setLicenceLength: null,
  token: false,
};

const mapStateToProps = state => ({
  licences: state.totalLicences.licences,
  selectedLicences: state.totalLicences.selectedLicences,
  token: state.auth.token !== null,
  paidOrder: state.payment.paidOrder,
});

export default connect(
  mapStateToProps,
  { initLicences, setLicenceLength }
)(App);

export { App };
