/* eslint-disable jsx-a11y/click-events-have-key-events */
/*
 * ⬆️ Disabled because the modal is listening using useEffect
 * Eslint isn't smart enough to catch this edge case 🤷‍.
 */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Logo from '../Logo/Logo';

const style = {
  position: 'fixed',
  top: '0',
  left: '0',
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(0,0,0,0.7)',
  textAlign: 'center',
  color: '#004e63',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
};

const innerStyle = {
  maxWidth: '450px',
  width: '95%',
  padding: '25px',
  backgroundColor: '#fff',
  boxShadow: '0 0 5px #000',
  borderRadius: '5px',
};

const Modal = ({ message, hideModal, children }) => {
  useEffect(() => {
    document.addEventListener('keydown', hideModal, false);
    return () => {
      document.removeEventListener('keydown', hideModal, false);
    };
  });

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div style={style} className="modalContent" onClick={hideModal}>
      <div style={innerStyle}>
        <Logo />
        <p style={{ marginTop: '30px' }}>{message}</p>
        <button type="button" onClick={hideModal}>
          {children}
        </button>
      </div>
    </div>
  );
};

Modal.propTypes = {
  message: PropTypes.string.isRequired,
  hideModal: PropTypes.func,
  children: PropTypes.node.isRequired,
};

Modal.defaultProps = {
  hideModal: null,
};

export default Modal;
