import React from 'react';
import { connect } from 'react-redux';
import { Elements, StripeProvider } from 'react-stripe-elements';
import PropTypes from 'prop-types';
import history from '../../history';
import PageTitle from '../../components/PageTitle/PageTitle';
import PaymentForm from '../../components/Forms/PaymentForm';
import Mixpanel from '../../mixpanel';
import { default as env } from '../../config';

class Purchase extends React.Component {
  constructor() {
    super();
    this.state = {
      countViews: 0,
      stripeKey: null,
    };
  }

  componentDidMount() {
    const { countViews } = this.state;
    const { selectedLicences } = this.props;
    if (countViews === 0 && selectedLicences) Mixpanel.track('[PAGE] Purchase');
    this.setState({ countViews: 1 });
    if (window.Stripe) {
      this.setState({ stripeKey: window.Stripe(env.STRIPE_KEY) });
    } else {
      document.querySelector('#stripe-js').addEventListener('load', () => {
        // eslint-disable-next-line react/no-unused-state
        this.setState({ stripe: window.Stripe(env.STRIPE_KEY) });
      });
    }
  }

  render() {
    const { selectedLicences } = this.props;
    const { stripeKey } = this.state;
    if (!selectedLicences) {
      history.push('/sign-in');
      return <></>;
    }
    return (
      <>
        <PageTitle>Payment</PageTitle>
        <StripeProvider stripe={stripeKey}>
          <Elements>
            <PaymentForm />
          </Elements>
        </StripeProvider>
      </>
    );
  }
}

Purchase.propTypes = {
  selectedLicences: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  userData: state.auth.userData,
  selectedLicences: state.totalLicences.selectedLicences,
  token: state.auth.token,
  updatedInfo: state.auth.updatedInfo,
});

export default connect(mapStateToProps)(Purchase);
