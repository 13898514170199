import * as actionTypes from './actionTypes';

export const updateMarketingPreference = () => ({
  type: actionTypes.MARKETING_UPDATES,
});

export const termsAgreed = () => ({
  type: actionTypes.TERMS_AGREED,
});

export const paidOrder = () => async dispatch => {
  await dispatch({
    type: actionTypes.PAID_ORDER,
  });
};

export const orderSummary = order => ({
  type: actionTypes.ORDER_SUMMARY,
  payload: order,
});
