import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  auth,
  selectLicences,
  registerEmail,
  handleError,
} from '../../actions';
import SignInForm from '../../components/Forms/SignInForm';
import PageTitle from '../../components/PageTitle/PageTitle';
import Modal from '../../components/Modal';
import Mixpanel from '../../mixpanel';

const SignIn = ({
  auth,
  selectLicences,
  registerEmail,
  handleError,
  error,
  loading,
}) => {
  const [countViews, setCountViews] = useState(0);

  useEffect(() => {
    if (countViews === 0) Mixpanel.track('[PAGE] Sign in');
    setCountViews(1);
    selectLicences();
    registerEmail();
  }, [selectLicences, registerEmail, countViews]);

  const onSignIn = formValues => {
    auth(formValues, false);
  };

  const renderError = () => {
    if (error) {
      return (
        <Modal message={error} hideModal={handleError}>
          Try again
        </Modal>
      );
    }
    return null;
  };

  return (
    <>
      <PageTitle>Sign in</PageTitle>
      <div className="subtitle">
        We’re sorry that you weren’t able to complete your transaction before,
        but we can take you back to where you left off as soon as you sign in.
      </div>
      <SignInForm
        onSubmitForm={formValues => {
          onSignIn(formValues);
        }}
        loading={loading}
      />
      {renderError()}
    </>
  );
};

const mapStateToProps = state => ({
  error: state.auth.error,
  loading: state.auth.loading,
});

SignIn.propTypes = {
  auth: PropTypes.func.isRequired,
  selectLicences: PropTypes.func.isRequired,
  registerEmail: PropTypes.func.isRequired,
  handleError: PropTypes.func.isRequired,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  loading: PropTypes.bool.isRequired,
};

SignIn.defaultProps = {
  error: null,
};

export default connect(
  mapStateToProps,
  {
    auth,
    registerEmail,
    selectLicences,
    handleError,
  }
)(SignIn);
