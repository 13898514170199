import * as actionTypes from '../actions/actionTypes';

const initialState = (window.Cypress && window.initialState) || {
  token: null,
  regId: null,
  userId: null,
  userData: null,
  registered: false,
  zohoLead: false,
  updatedInfo: false,
  error: null,
  loading: false,
};

const authStart = state => ({
  ...state,
  loading: true,
});

const authSuccess = (state, action) => ({
  ...state,
  loading: false,
  error: false,
  zohoLead: true,
  regId: action.token,
  userData: action.userData,
  userId: action.userId,
});

const authFailed = (state, action) => ({
  ...state,
  loading: false,
  error: action.payload,
});

const handleError = state => ({
  ...state,
  error: false,
});

const registerEmail = state => ({
  ...state,
  registered: true,
});

const setPassword = (state, action) => ({
  ...state,
  token: action.payload,
});

const confirmDetails = state => ({
  ...state,
  updatedInfo: !state.updatedInfo,
});

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.AUTH_START:
      return authStart(state, action);
    case actionTypes.AUTH_SUCCESS:
      return authSuccess(state, action);
    case actionTypes.AUTH_FAILED:
      return authFailed(state, action);
    case actionTypes.HANDLE_ERROR:
      return handleError(state, action);
    case actionTypes.REGISTER_EMAIL:
      return registerEmail(state, action);
    case actionTypes.SET_PASSWORD:
      return setPassword(state, action);
    case actionTypes.CONFIRM_DETAILS:
      return confirmDetails(state, action);
    default:
      return state;
  }
};

export default authReducer;
