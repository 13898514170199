import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Logo from '../Logo/Logo';
import classes from '../../assets/css/Navbar.module.css';

export const Navbar = ({ selectedLicences, token, paidOrder }) => {
  const showCompletion = condition =>
    !condition
      ? `${classes.NavbarNumber}`
      : `${classes.NavbarNumber} ${classes.Complete}`;

  const redirectCondition = (condition, pathName) =>
    !condition ? '/' : pathName;

  const disabled = condition => (!condition ? `${classes.Disabled}` : '');

  const ariaCondition = condition => !condition;

  return (
    <>
      <Logo />
      <div className={classes.Navbar}>
        <div className={classes.NavbarContainer}>
          <Link to="/">
            <div className={showCompletion(selectedLicences)}>1</div>
            <div>Choose licence</div>
          </Link>
          <div className={classes.Separator} />
          <Link
            to={redirectCondition(selectedLicences, '/create-account')}
            className={disabled(selectedLicences)}
            aria-hidden={ariaCondition(selectedLicences)}
            disabled={ariaCondition(selectedLicences)}
          >
            <div className={showCompletion(token)}>2</div>
            <div>Create account</div>
          </Link>
          <div className={classes.Separator} />
          <Link
            to={redirectCondition(token, '/confirm-details')}
            className={disabled(token)}
            aria-hidden={ariaCondition(token)}
            disabled={ariaCondition(token)}
          >
            <div className={showCompletion(paidOrder)}>3</div>
            <div>Pay and confirm</div>
          </Link>
          <div className={classes.Separator} />
          <Link
            to={redirectCondition(paidOrder, '/download')}
            className={disabled(paidOrder)}
            aria-hidden={ariaCondition(paidOrder)}
            disabled={ariaCondition(paidOrder)}
          >
            <div className={`${classes.NavbarNumber}`}>4</div>
            <div>Download VU.CITY</div>
          </Link>
        </div>
      </div>
    </>
  );
};

Navbar.propTypes = {
  selectedLicences: PropTypes.bool,
  token: PropTypes.bool,
  paidOrder: PropTypes.bool,
};

Navbar.defaultProps = {
  selectedLicences: false,
  token: false,
  paidOrder: false,
};

const mapStateToProps = state => ({
  selectedLicences: state.totalLicences.selectedLicences,
  token: state.auth.token !== null,
  paidOrder: state.payment.paidOrder,
});

export default connect(mapStateToProps)(Navbar);
