import React from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import {
  injectStripe,
  CardNumberElement,
  CardCVCElement,
  CardExpiryElement,
} from 'react-stripe-elements';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import history from '../../history';
import { paidOrder, orderSummary } from '../../actions';
import classes from '../../assets/css/PaymentForm.module.css';
import Spinner from '../Spinner/Spinner';
import Mixpanel from '../../mixpanel';
import Modal from '../Modal';
import OrderSummary from '../OrderSummary/OrderSummary';
import { default as env } from '../../config';

// Because the Stripe elements are in an iframe it can't use classes from our CSS.
// So we inject styles using CSS-in-js as a workaround 🚐
const createOptions = () => ({
  style: {
    base: {
      fontSize: '16px',
      color: '#495057',
      fontFamily:
        'Roboto,"Segoe UI","Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
      '::placeholder': {
        color: '#868e96',
      },
    },
    invalid: {
      color: '#9e2146',
    },
  },
});

class PaymentForm extends React.Component {
  constructor() {
    super();
    this.state = {
      nameOnCard: '',
      postcode: '',
      errorMessage: '',
      loading: false,
    };
    this.onPaymentSubmit = this.onPaymentSubmit.bind(this);
    this.authorizePayment = this.authorizePayment.bind(this);
  }

  async onPaymentSubmit(ev) {
    const {
      company,
      email,
      plan,
      licences,
      stripe,
      paidOrder,
      sessionId,
      orderSummary,
      name,
    } = this.props;
    const { nameOnCard, postcode } = this.state;

    const licencesArr = [];
    licences.map(licence =>
      licence.users !== undefined && licence.users > 0
        ? licencesArr.push(licence)
        : licencesArr
    );

    this.setState({ loading: true });
    Mixpanel.identify(email);
    ev.preventDefault();

    if (!nameOnCard || !postcode) {
      this.setState({
        errorMessage:
          'Please note that all mandatory fields must be completed to proceed.',
        loading: false,
      });
    } else {
      const { error, token } = await stripe.createToken({
        name: nameOnCard,
        address_zip: postcode,
      });
      if (error) {
        this.setState({ errorMessage: error.message, loading: false });
      } else {
        this.setState({ errorMessage: '' });
        const data = {
          company,
          email,
          plan,
          sessionId,
          name,
          licenses: licencesArr,
          nameOnCard: token.card.name,
          token: token.id,
        };
        try {
          const response = await axios.post(env.CREATE_SUBSCRIPTION, data);
          orderSummary(response.data);
          if (response.data.status === 'active') {
            Mixpanel.track('[ACTION] Successful purchase');
            this.setState({ errorMessage: '' });
            history.push('/download');
            paidOrder();
          } else {
            Mixpanel.track('[ACTION] Unsuccessful purchase');
            this.setState({
              errorMessage:
                'We are sorry but the payment has not gone through. Please try again!',
              loading: false,
            });
          }
        } catch (e) {
          Mixpanel.track('[ACTION] Unsuccessful purchase');

          this.setState({
            errorMessage:
              'We are sorry but the payment has not gone through. Please try again!',
            loading: false,
          });
        }
      }
    }
  }

  authorizePayment() {
    const { loading, errorMessage } = this.state;
    if (loading) {
      return (
        <Modal message="Thank you! Please wait while we authorise your payment.">
          <Spinner fontSize="18px" />
        </Modal>
      );
    }
    if (errorMessage) {
      return (
        <Modal
          message={errorMessage}
          hideModal={() => this.setState({ errorMessage: '' })}
        >
          Try again
        </Modal>
      );
    }
    return null;
  }

  render() {
    const { fontSize } = this.props;
    const { name, postcode, loading } = this.state;
    return (
      <>
        <div className="subtitle">Please add your payment details below.</div>
        <form onSubmit={this.onPaymentSubmit}>
          <div className={classes.FormRow}>
            <label>
              <span>Card number</span>
              <div className={classes.InputContainer}>
                <CardNumberElement {...createOptions(fontSize)} />
              </div>
            </label>
          </div>
          <div className={classes.FormRow}>
            <label>
              <span>Name on card</span>
              <div className={classes.InputContainer}>
                <input
                  onChange={e => this.setState({ nameOnCard: e.target.value })}
                  placeholder="Name on card"
                  value={name}
                  name="name"
                  type="text"
                />
              </div>
            </label>
          </div>
          <div className={`${classes.FormRow} ${classes.InfoRow}`}>
            <label>
              <span>Expiration date</span>
              <div className={classes.InputContainer}>
                <CardExpiryElement {...createOptions(fontSize)} />
              </div>
            </label>
            <label>
              <div
                style={{
                  display: 'inline-flex',
                  width: '100%',
                }}
              >
                <span>CVC</span>
                <div className={classes.CVCQuestion}>?</div>
                <div className={classes.CVCResponse}>
                  3-digit security code usually found on the back of your card.
                </div>
              </div>
              <div className={classes.InputContainer}>
                <CardCVCElement {...createOptions(fontSize)} />
              </div>
            </label>
          </div>
          <div className={classes.FormRow}>
            <label>
              <span>Billing address postcode</span>
              <div className={classes.InputContainer}>
                <input
                  onChange={e =>
                    this.setState({
                      postcode: e.target.value,
                    })
                  }
                  placeholder="Billing address postcode"
                  value={postcode}
                  name="postcode"
                  type="text"
                />
              </div>
            </label>
          </div>

          <div style={{ margin: '50px 0 15px' }}>
            <OrderSummary />
          </div>

          <div className={classes.PayButton}>
            <Link className={classes.GoBack} to="/confirm-details">
              Go back
            </Link>
            <button type="submit">
              {loading ? <Spinner fontSize="18px" /> : 'Complete purchase'}
            </button>
          </div>
        </form>
        {this.authorizePayment()}
      </>
    );
  }
}

PaymentForm.propTypes = {
  company: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  plan: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  licences: PropTypes.array.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  stripe: PropTypes.object.isRequired,
  paidOrder: PropTypes.func.isRequired,
  sessionId: PropTypes.string.isRequired,
  orderSummary: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  fontSize: PropTypes.string,
};

PaymentForm.defaultProps = {
  fontSize: '16px',
};

const mapStateToProps = state => ({
  company: state.auth.userData.company,
  email: state.auth.userData.email,
  plan: state.totalLicences.plan,
  licences: state.totalLicences.licences,
  sessionId: state.auth.token,
  name: state.auth.userData.name,
});

export default injectStripe(
  connect(
    mapStateToProps,
    { paidOrder, orderSummary }
  )(PaymentForm)
);
