import mixpanel from 'mixpanel-browser';
import { default as env } from './config';

mixpanel.init(env.MIXPANEL_KEY);

// eslint-disable-next-line camelcase
const env_check = process.env.NODE_ENV === 'production';

const Mixpanel = {
  identify: id => {
    // eslint-disable-next-line camelcase
    if (env_check) mixpanel.identify(id);
  },
  alias: id => {
    // eslint-disable-next-line camelcase
    if (env_check) mixpanel.alias(id);
  },
  track: (name, props) => {
    // eslint-disable-next-line camelcase
    if (env_check) mixpanel.track(name, props);
  },
  people: {
    set: props => {
      // eslint-disable-next-line camelcase
      if (env_check) mixpanel.people.set(props);
    },
  },
};

export default Mixpanel;
