import axios from 'axios';
import * as actionTypes from './actionTypes';
import { default as env } from '../config';

export const addLicence = (id, plan) => ({
  type: actionTypes.ADD_LICENCE,
  payload: id,
  plan,
});

export const removeLicence = (licence, plan) => ({
  type: actionTypes.REMOVE_LICENCE,
  payload: licence,
  plan,
});

export const setLicences = licences => ({
  type: actionTypes.SET_LICENCES,
  payload: licences,
});

export const fetchLicencesFailed = error => ({
  type: actionTypes.FETCH_LICENCES_FAILED,
  error,
});

export const selectLicences = () => ({
  type: actionTypes.SELECT_LICENCES,
});

export const selectPlan = () => ({
  type: actionTypes.SELECT_PLAN,
});

export const updateState = returnedState => async dispatch => {
  await dispatch({
    type: actionTypes.UPDATE_STATE,
    payload: returnedState,
  });
};

export const initLicences = () => async dispatch => {
  try {
    const response = await axios.get(env.GET_LICENSES);
    if (response.error) {
      dispatch(fetchLicencesFailed(response.error));
    } else {
      // eslint-disable-next-line no-underscore-dangle
      dispatch(setLicences(response.data._get_pricing_licenses));
    }
  } catch (e) {
    dispatch(
      fetchLicencesFailed(
        'There seems to be a problem. Please try again later.'
      )
    );
  }
};
