import * as actionTypes from '../actions/actionTypes';

const initialState = (window.Cypress && window.initialState) || {
  paidOrder: false,
  marketingUpdates: false,
  termsAgreed: false,
  orderSummary: null,
};

const marketingUpdates = state => ({
  ...state,
  marketingUpdates: !state.marketingUpdates,
});

const termsAgreed = state => ({
  ...state,
  termsAgreed: !state.termsAgreed,
});

const paidOrder = state => ({
  ...state,
  paidOrder: true,
});

const orderSummary = (state, action) => ({
  ...state,
  orderSummary: action.payload,
});

const paymentReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.MARKETING_UPDATES:
      return marketingUpdates(state, action);
    case actionTypes.TERMS_AGREED:
      return termsAgreed(state, action);
    case actionTypes.PAID_ORDER:
      return paidOrder(state, action);
    case actionTypes.ORDER_SUMMARY:
      return orderSummary(state, action);
    default:
      return state;
  }
};

export default paymentReducer;
