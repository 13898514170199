import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Field } from 'redux-form';
import { renderInput } from './renderFields';
import classes from '../../assets/css/CreateAccount.module.css';
import Spinner from '../Spinner/Spinner';

const SignInForm = ({ handleSubmit, onSubmitForm, loading }) => (
  <form
    className={classes.CreateAccountForm}
    onSubmit={handleSubmit(onSubmitForm)}
  >
    <div className={classes.FormRow}>
      <div className={classes.Label}>
        <label>Email</label>
      </div>
      <Field component={renderInput} name="email" type="email" label="Email" />
    </div>
    <div className={classes.FormRow}>
      <div className={classes.Label}>
        <label>Password</label>
      </div>
      <Field
        component={renderInput}
        name="password"
        type="password"
        label="Password"
      />
    </div>
    <div style={{ textAlign: 'right' }}>
      <button type="submit">
        {loading ? <Spinner fontSize="18px" /> : 'Sign In'}
      </button>
    </div>
  </form>
);

const validate = formValues => {
  const errors = {};

  if (!formValues.email) {
    errors.email = 'Mandatory field';
  } else if (formValues.email.indexOf('@') < 0) {
    errors.email = 'Please enter a valid email';
  }

  if (!formValues.password) {
    errors.password = 'Mandatory field';
  }

  return errors;
};

SignInForm.propTypes = {
  onSubmitForm: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default reduxForm({
  validate,
  form: 'signInForm',
})(SignInForm);
