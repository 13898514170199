import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  setPassword,
  handleError,
  selectLicences,
  registerEmail,
} from '../../actions';
import PageTitle from '../../components/PageTitle/PageTitle';
import SetPasswordForm from '../../components/Forms/SetPasswordForm';
import Modal from '../../components/Modal';
import Mixpanel from '../../mixpanel';

const SetPassword = ({
  setPassword,
  match,
  error,
  handleError,
  loading,
  selectLicences,
  registerEmail,
}) => {
  useEffect(() => {
    Mixpanel.track('[PAGE] Set password');
    selectLicences();
    registerEmail();
  }, [selectLicences, registerEmail]);

  const onCreatePassword = formValues => {
    // eslint-disable-next-line react/prop-types
    setPassword(formValues, match.params.id);
  };

  const renderError = () => {
    if (error) {
      return (
        <Modal message={error} hideModal={handleError}>
          Try again
        </Modal>
      );
    }
  };

  return (
    <>
      <PageTitle>Set your password</PageTitle>
      <div className="subtitle">
        To complete the set up of your account, enter a password.
      </div>

      <SetPasswordForm
        loading={loading}
        onSubmitForm={formValues => {
          onCreatePassword(formValues.password);
        }}
      />
      {renderError()}
    </>
  );
};

SetPassword.propTypes = {
  setPassword: PropTypes.func.isRequired,
  match: PropTypes.shape({
    path: PropTypes.string,
    url: PropTypes.string,
    isExact: PropTypes.bool,
  }).isRequired,
  error: PropTypes.bool,
  handleError: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  selectLicences: PropTypes.func.isRequired,
  registerEmail: PropTypes.func.isRequired,
};

SetPassword.defaultProps = {
  error: null,
  loading: false,
};

const mapStateToProps = state => ({
  error: state.auth.error,
  loading: state.auth.loading,
});

export default connect(
  mapStateToProps,
  {
    setPassword,
    handleError,
    selectLicences,
    registerEmail,
  }
)(SetPassword);
