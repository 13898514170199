import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Field } from 'redux-form';
import { renderInput } from './renderFields';
import classes from '../../assets/css/CreateAccount.module.css';
import Spinner from '../Spinner/Spinner';

const SetPasswordForm = ({ handleSubmit, onSubmitForm, loading }) => (
  <form
    className={classes.CreateAccountForm}
    onSubmit={handleSubmit(onSubmitForm)}
  >
    <div className={classes.FormRow}>
      <div className={classes.Label}>
        <label>Password*</label>
      </div>
      <Field
        component={renderInput}
        name="password"
        type="password"
        label="Password"
      />
    </div>
    <div className={classes.FormRow}>
      <div className={classes.Label}>
        <label>Confirm Password*</label>
      </div>
      <Field
        component={renderInput}
        name="confirmpassword"
        type="password"
        label="Confirm Password"
      />
    </div>
    <div style={{ textAlign: 'right' }}>
      <button type="submit">
        {loading ? <Spinner fontSize="18px" /> : 'Set password'}
      </button>
    </div>
  </form>
);

const validate = formValues => {
  const errors = {};

  if (!formValues.password) {
    errors.password = 'Mandatory field';
  } else if (formValues.password.length < 6) {
    errors.password = 'Password needs to have at least 6 characters.';
  }

  if (!formValues.confirmpassword) {
    errors.confirmpassword = 'Mandatory field';
  }

  if (formValues.password && formValues.confirmpassword) {
    if (formValues.password !== formValues.confirmpassword) {
      errors.confirmpassword = "Password doesn't match.";
    }
  }

  return errors;
};

SetPasswordForm.propTypes = {
  onSubmitForm: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

SetPasswordForm.defaultProps = {
  loading: false,
};

export default reduxForm({
  validate,
  form: 'setPasswordForm',
})(SetPasswordForm);
