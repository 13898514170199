import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classes from '../../assets/css/LicenceList.module.css';
import { addLicence, removeLicence } from '../../actions';
import LicenceItem from './LicenceItem/LicenceItem';
// import Spinner from "../Spinner/Spinner";

const LicenceList = props => {
  const { plan, addLicence, removeLicence, licences } = props;

  const { planValue, annualPrice, monthlyPrice } = plan;

  const regImg = require.context('../../assets/img', true, /\.png$/);
  const pngs = regImg
    .keys()
    .reduce((images, path) => ({ ...images, [path]: regImg(path) }), {});

  const squareMeters = name => {
    if (name === 'Belfast') return 57;
    if (name === 'Berlin') return 6;
    if (name === 'Birmingham') return 17;
    if (name === 'Brighton') return 21;
    if (name === 'Bristol') return 70;
    if (name === 'Cambridge') return 64;
    if (name === 'Dublin') return 106;
    if (name === 'Edinburgh') return 62;
    if (name === 'Liverpool') return 47;
    if (name === 'London') return 'All of London modelled';
    if (name === 'Manchester') return 102;
    if (name === 'New York') return 33;
    if (name === 'Oxford') return 12;
    if (name === 'Paris') return 12;
    if (name === 'Sydney') return 1;
    if (name === 'Woking') return 4;
  };

  const renderLicences = () => {
    if (!licences) {
      return (
        <p className={classes.Error}>
          There seems to be a problem. We cannot retrieve the licences. Please
          try later.
        </p>
      );
    }
    if (licences.length === 0) {
      return (
        <p className={classes.Error}>
          We are sorry, but we couldn&apos;t find any licences.
        </p>
      );
    }

    const licencesList = licences
      .sort((a, b) => a.name.localeCompare(b.name))
      .map(licence => (
        <LicenceItem
          key={licence.id}
          name={licence.name}
          imgSrc={pngs[`./${licence.id}.png`]}
          users={licence.users}
          price={planValue === 'annual' ? annualPrice : monthlyPrice}
          plan={planValue}
          squareM={squareMeters(licence.name)}
          addLicence={() => addLicence(licence.id, planValue)}
          removeLicence={() => removeLicence(licence.id, planValue)}
        />
      ));
    return licencesList;
  };

  return <div className={classes.LicenceList}>{renderLicences()}</div>;
};

LicenceList.propTypes = {
  plan: PropTypes.shape().isRequired,
  addLicence: PropTypes.func.isRequired,
  removeLicence: PropTypes.func.isRequired,
  licences: PropTypes.arrayOf(PropTypes.object),
};

LicenceList.defaultProps = {
  licences: null,
};
const mapStateToProps = state => ({
  plan: state.plan,
});

export default connect(
  mapStateToProps,
  {
    addLicence,
    removeLicence,
  }
)(LicenceList);
