import React from 'react';
import PropTypes from 'prop-types';
import classes from '../../assets/css/CreateAccount.module.css';

export const renderError = ({ error, touched }) => {
  if (touched && error) {
    return <div className={classes.Error}>{error}</div>;
  }
};

export const renderInput = ({ input, label, type, meta }) => (
  <>
    <div className={classes.InputContainer}>
      {renderError(meta)}
      <input {...input} type={type} aria-label={label} />
    </div>
  </>
);

renderInput.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  input: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['text', 'password', 'email']),
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
};

renderInput.defaultProps = {
  type: 'text',
  meta: null,
};

export const renderSelect = ({ input, label, meta, children }) => (
  <>
    <div className={classes.InputContainer}>
      {renderError(meta)}
      <select {...input} aria-label={label}>
        {children}
      </select>
    </div>
  </>
);

renderSelect.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  input: PropTypes.object.isRequired,
  label: PropTypes.string,
  children: PropTypes.node.isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
};

renderSelect.defaultProps = {
  meta: null,
  label: null,
};
