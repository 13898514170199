export const LICENCE_LENGTH = 'LICENCE_LENGTH';

export const ADD_LICENCE = 'ADD_LICENCE';
export const REMOVE_LICENCE = 'REMOVE_LICENCE';
export const SET_LICENCES = 'SET_LICENCES';
export const SELECT_LICENCES = 'SELECT_LICENCES';
export const SELECT_PLAN = 'SELECT_PLAN';
export const FETCH_LICENCES_FAILED = 'FETCH_LICENCES_FAILED';
export const UPDATE_STATE = 'UPDATE_STATE';

export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAILED = 'AUTH_FAILED';
export const HANDLE_ERROR = 'HANDLE_ERROR';
export const REGISTER_EMAIL = 'REGISTER_EMAIL';
export const SET_PASSWORD = 'SET_PASSWORD';

export const CONFIRM_DETAILS = 'CONFIRM_DETAILS';
export const MARKETING_UPDATES = 'MARKETING_UPDATES';
export const TERMS_AGREED = 'TERMS_AGREED';
export const PAID_ORDER = 'PAID_ORDER';
export const ORDER_SUMMARY = 'ORDER_SUMMARY';
