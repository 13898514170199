import React from 'react';
import PropTypes from 'prop-types';

const Spinner = ({ fontSize }) => (
  <div style={{ textAlign: 'center', width: '100%' }}>
    <i className="fa fa-spinner fa-spin" style={{ fontSize }} />
  </div>
);

Spinner.propTypes = {
  fontSize: PropTypes.string,
};

Spinner.defaultProps = {
  fontSize: '16px',
};
export default Spinner;
