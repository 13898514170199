import * as actionTypes from './actionTypes';

// could have multiple exports in future, eslint is not smart
// enough to figure this out, if you have multiple exports then
// turn this off!
// eslint-disable-next-line import/prefer-default-export
export const setLicenceLength = length => ({
  type: actionTypes.LICENCE_LENGTH,
  payload: length,
});
