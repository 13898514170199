import * as actionTypes from '../actions/actionTypes';

const initialState = (window.Cypress && window.initialState) || {
  planValue: 'monthly',
  annualPrice: 5000,
  monthlyPrice: 585,
};

const licenceLengthReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LICENCE_LENGTH:
      return {
        ...state,
        planValue: action.payload,
      };
    default:
      return state;
  }
};

export default licenceLengthReducer;
