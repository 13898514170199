/* eslint-disable jsx-a11y/no-onchange */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import classes from '../../assets/css/ChooseLicence.module.css';
import { setLicenceLength, selectLicences } from '../../actions';
import PageTitle from '../../components/PageTitle/PageTitle';
import LicenceList from '../../components/LicenceList/LicenceList';
import OrderSummary from '../../components/OrderSummary/OrderSummary';
import SearchBar from '../../components/SearchBar/SearchBar';
import Mixpanel from '../../mixpanel';

const ChooseLicence = props => {
  const {
    plan,
    totalLicences,
    setLicenceLength,
    selectLicences,
    licences,
    prices,
    totalPrice,
  } = props;

  const [filteredLicences, setFilteredLicences] = useState([]);
  const [countViews, setCountViews] = useState(0);

  useEffect(() => {
    if (countViews === 0) Mixpanel.track('[PAGE] Choose Licence');
    setCountViews(1);
    setFilteredLicences(licences);
  }, [licences, countViews]);

  const handleSearch = e => {
    let currentList = [];
    let newFiltered = [];
    if (e.target.value !== '') {
      currentList = licences;
      newFiltered = currentList.filter(licence => {
        const licenceName = licence.name.toLowerCase();
        const input = e.target.value.toLowerCase();
        return licenceName.includes(input);
      });
    } else {
      newFiltered = licences;
    }
    setFilteredLicences(newFiltered);
  };

  const disableLicenceLength = licLen => {
    if (plan === licLen && totalLicences > 0) {
      return true;
    }
    return false;
  };

  const renderCorrectSection = () => (
    <>
      <SearchBar onSearch={handleSearch} />
      <div className={classes.LicenceLength}>
        <label htmlFor="licenceLength">
          <span>Change your plan</span>
          <select
            id="licenceLength"
            onChange={e => setLicenceLength(e.target.value)}
            value={plan}
          >
            <option
              aria-label="Monthly licence"
              value="monthly"
              disabled={disableLicenceLength('annual')}
            >
              Monthly
            </option>
            <option
              aria-label="Annual licence"
              value="annual"
              disabled={disableLicenceLength('monthly')}
            >
              Annual
            </option>
          </select>
        </label>
      </div>
      <div className={classes.PriceBar}>
        £
        {plan === 'annual'
          ? prices.annualPrice.toLocaleString()
          : prices.monthlyPrice.toLocaleString()}{' '}
        price / {plan} / user (excl. VAT)
      </div>
      <LicenceList licences={filteredLicences} />
      <OrderSummary />

      <div className={classes.ButtonContainer}>
        <Link disabled={!(totalPrice > 0)} to="create-account">
          <button
            type="button"
            onClick={selectLicences}
            data-test="buyNowButton"
            disabled={!(totalPrice > 0)}
            className={classes.ContinueButton}
          >
            Buy now
          </button>
        </Link>
        <div className={classes.Disclaimer}>
          <p>
            If you&apos;re a public sector client, would like details of areas
            covered or have any other questions, please{' '}
            <a href="https://vu.city/contact-us/">contact our sales team.</a>
          </p>
        </div>
      </div>
    </>
  );

  return (
    <>
      <PageTitle>Choose a licence</PageTitle>
      <div className="subtitle">
        We’re delighted that you want to join the growing list of professionals
        adopting VU.CITY. We offer a selection of licences to cater for your
        needs.
      </div>
      {renderCorrectSection()}
    </>
  );
};

ChooseLicence.propTypes = {
  plan: PropTypes.string.isRequired,
  totalLicences: PropTypes.number.isRequired,
  setLicenceLength: PropTypes.func.isRequired,
  selectLicences: PropTypes.func.isRequired,
  // eslint-disable-next-line react/require-default-props
  licences: PropTypes.arrayOf(PropTypes.object),
  // eslint-disable-next-line react/forbid-prop-types
  prices: PropTypes.PropTypes.object.isRequired,
  totalPrice: PropTypes.number.isRequired,
};

const mapStateToProps = state => ({
  plan: state.plan.planValue,
  licences: state.totalLicences.licences,
  totalLicences: state.totalLicences.totalLicences,
  totalPrice: state.totalLicences.totalPrice,
  prices: state.plan,
  selectedLicenceLength: state.totalLicences.plan,
});

export default connect(
  mapStateToProps,
  { setLicenceLength, selectLicences }
)(ChooseLicence);
