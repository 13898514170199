import * as actionTypes from '../actions/actionTypes';

const initialState = (window.Cypress && window.initialState) || {
  licences: null,
  totalLicences: 0,
  totalPrice: 0,
  subTotal: 0,
  discountedPrice: 0,
  plan: null,
  selectedLicences: false,
  selectedPlan: false,
  error: false,
  ordering: false,
};

const LICENCES_PRICES = {
  annual: 5000,
  monthly: 585,
};

export const VAT_THRESHOLD = 0.2;

const initialUsers = 0;
const increment = 1;
const decrement = -1;

const calculateTotalPrice = (state, action, value) =>
  (state.totalLicences + value) * LICENCES_PRICES[action.plan];
const calculateVat = (state, action, value) => {
  const totalPrice = calculateTotalPrice(state, action, value);
  const totalPriceVat = totalPrice + VAT_THRESHOLD * totalPrice;

  return totalPriceVat;
};

const updateLicencesHandler = (state, action, value) =>
  state.licences.map(licence => {
    if (licence.id === action.payload) {
      if (!licence.users) return { ...licence, users: initialUsers + value };
      return { ...licence, users: licence.users + value };
    }
    return licence;
  });

const addLicence = (state, action) => {
  const updatedLicences = updateLicencesHandler(state, action, increment);
  // const discountThreshold = calculateRemainder(
  //     state.totalLicences,
  //     increment
  // );

  // if (discountThreshold === 0) {
  //     countLicences++;
  // }

  return {
    ...state,
    licences: updatedLicences,
    totalLicences: state.totalLicences + increment,
    totalPrice: calculateVat(state, action, increment),
    subTotal: state.subTotal + LICENCES_PRICES[action.plan],
    plan: action.plan,
    // discountedPrice: calculateTotalDiscountedPrice(action, increment),
    ordering: true,
  };
};

const removeLicence = (state, action) => {
  const updatedLicences = updateLicencesHandler(state, action, decrement);
  // const discountThreshold = calculateRemainder(
  //     state.totalLicences,
  //     decrement
  // );

  // if (discountThreshold === DISCOUNT_THRESHOLD - 1) {
  //     countLicences--;
  // }

  return {
    ...state,
    licences: updatedLicences,
    totalLicences: state.totalLicences + decrement,
    totalPrice: calculateVat(state, action, decrement),
    // discountedPrice: calculateTotalDiscountedPrice(action, decrement),
    subTotal: state.subTotal - LICENCES_PRICES[action.plan],
    plan: action.plan,
    ordering: true,
  };
};

const setLicences = (state, action) => ({
  ...state,
  licences: action.payload,
});

const fetchLicencesFailed = (state, action) => ({
  ...state,
  error: action.error,
});

const selectLicences = state => ({
  ...state,
  selectedLicences: true,
});

const selectPlan = state => ({
  ...state,
  selectedPlan: true,
});

const updateState = (state, action) => {
  const returnedLicences = [];
  action.payload.licenses.map(licence => returnedLicences.push(licence));

  return {
    ...state,
    licences: state.licences.map(licence => ({
      ...returnedLicences.find(
        newLicence => licence.id === newLicence.id && newLicence
      ),
      ...licence,
    })),
    totalLicences: action.payload.totalLicenses,
    plan: action.payload.plan,
    ordering: true,
    subTotal: action.payload.subTotal,
    totalPrice: action.payload.totalPrice,
  };
};

const chooseLicenceReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ADD_LICENCE:
      return addLicence(state, action);
    case actionTypes.REMOVE_LICENCE:
      return removeLicence(state, action);
    case actionTypes.FETCH_LICENCES_FAILED:
      return fetchLicencesFailed(state, action);
    case actionTypes.SELECT_LICENCES:
      return selectLicences(state, action);
    case actionTypes.SET_LICENCES:
      return setLicences(state, action);
    case actionTypes.SELECT_PLAN:
      return selectPlan(state, action);
    case actionTypes.UPDATE_STATE:
      return updateState(state, action);
    default:
      return state;
  }
};

export default chooseLicenceReducer;
