import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';
import { updateMarketingPreference, termsAgreed } from '../../actions';
import { renderInput, renderSelect } from './renderFields';
import Spinner from '../Spinner/Spinner';
import OrderSummary from '../OrderSummary/OrderSummary';
import classes from '../../assets/css/CreateAccount.module.css';

export const CreateAccountForm = ({
  handleSubmit,
  onSubmitForm,
  registered,
  button,
  marketingUpdates,
  updateMarketingPreference,
  termsAgreed,
  agreedToTerms,
  disabled,
  loading,
  userData,
}) => (
  <form
    className={classes.CreateAccountForm}
    onSubmit={handleSubmit(onSubmitForm)}
  >
    <div className={classes.FormRow}>
      <div className={classes.Label}>
        <label>Name*</label>
      </div>
      <Field component={renderInput} name="name" type="text" label="Name" />
    </div>
    <div className={classes.FormRow}>
      <div className={classes.Label}>
        <label>Email*</label>
      </div>
      {registered ? (
        <input name="email" value={userData.email} readOnly />
      ) : (
        <Field
          component={renderInput}
          name="email"
          type="email"
          label="Email"
        />
      )}
    </div>
    <div className={classes.FormRow}>
      <div className={classes.Label}>
        <label>Company*</label>
      </div>
      <Field
        component={renderInput}
        name="company"
        type="text"
        label="Company"
      />
    </div>
    <div className={classes.FormRow}>
      <div className={classes.Label}>
        <label>Contact number</label>
      </div>
      <Field
        component={renderInput}
        name="contactNumber"
        type="text"
        label="Contact number"
      />
    </div>
    {registered ? (
      <>
        <div className={classes.FormRow}>
          <div className={classes.Label}>
            <label>Industry*</label>
          </div>
          <Field component={renderSelect} name="industry">
            <option />
            <option value="architect">Architect</option>
            <option value="consultant">Consultant</option>
            <option value="contractor">Contractor</option>
            <option value="developer">Developer</option>
            <option value="planningConsultant">Planning Consultant</option>
            <option value="realEstate">Real estate</option>
            <option value="other">Other</option>
            <option value="preferNot">Prefer not to say</option>
          </Field>
        </div>
        <div className={classes.Separator} />

        <OrderSummary location="/confirm-details" />

        <div className={classes.Separator} />

        <div style={{ textAlign: 'right', lineHeight: '1.5' }}>
          <p style={{ marginBottom: '0' }}>
            Every now and again we send out emails with the latest news on
            VU.CITY.
          </p>
          <br />
          <div className={classes.CheckboxContainer}>
            <input
              type="checkbox"
              onChange={updateMarketingPreference}
              defaultChecked={marketingUpdates ? 'checked' : null}
            />{' '}
            Opt in to receive VU.CITY communications this will keep you updated
            on features, coverage and how to get the most out of VU.CITY.
          </div>
          <div className={classes.CheckboxContainer}>
            <input
              type="checkbox"
              onChange={termsAgreed}
              required
              defaultChecked={agreedToTerms ? 'checked' : null}
            />{' '}
            I agree to the{' '}
            <a
              style={{
                color: '#004e63',
                paddingLeft: '5px',
                fontWeight: 'bold',
              }}
              href="https://vu.city/wp-content/uploads/2018/03/VUCITY-Terms-and-Conditions-2018.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms and Conditions.
            </a>
          </div>
        </div>
      </>
    ) : (
      <div className={`${classes.FormRow} ${classes.Disclaimer}`}>
        <p style={{ width: '100%' }}>
          You can assign licences to users post purchase.
          <br />
          New users will be asked to set their own password after purchase.
        </p>
      </div>
    )}
    <div className={classes.Button}>
      <button type="submit" disabled={disabled}>
        {loading ? <Spinner fontSize="18px" /> : `${button}`}
      </button>
      {!agreedToTerms && registered && (
        <div className={classes.Tooltip}>
          Please agree with our terms and conditions if you wish to continue.
        </div>
      )}
    </div>
  </form>
);

const validate = formValues => {
  const errors = {};
  const emailRegex = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{1,2}/;

  if (!formValues.name) {
    errors.name = 'Mandatory field';
  }

  if (!formValues.email) {
    errors.email = 'Mandatory field';
  } else if (!emailRegex.test(formValues.email)) {
    errors.email = 'Please enter a valid email';
  }

  if (!formValues.company) {
    errors.company = 'Mandatory field';
  }

  if (!formValues.industry) {
    errors.industry = 'Mandatory field';
  }

  return errors;
};

CreateAccountForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  onSubmitForm: PropTypes.func.isRequired,
  registered: PropTypes.bool,
  button: PropTypes.string.isRequired,
  marketingUpdates: PropTypes.bool,
  updateMarketingPreference: PropTypes.func.isRequired,
  termsAgreed: PropTypes.func.isRequired,
  agreedToTerms: PropTypes.bool,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  userData: PropTypes.object,
};

CreateAccountForm.defaultProps = {
  loading: false,
  disabled: false,
  registered: false,
  marketingUpdates: false,
  agreedToTerms: false,
  userData: null,
};

const mapStateToProps = state => ({
  registered: state.auth.registered,
  marketingUpdates: state.payment.marketingUpdates,
  agreedToTerms: state.payment.termsAgreed,
  loading: state.auth.loading,
  userData: state.auth.userData,
});

export default connect(
  mapStateToProps,
  { updateMarketingPreference, termsAgreed }
)(
  reduxForm({
    validate,
    form: 'createAccountForm',
  })(CreateAccountForm)
);

export const reduxFormComp = reduxForm({ validate, form: 'createAccountForm' })(
  CreateAccountForm
);
