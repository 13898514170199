import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import OrderSummary from '../../components/OrderSummary/OrderSummary';
import LicenceList from '../../components/LicenceList/LicenceList';
import PageTitle from '../../components/PageTitle/PageTitle';
import Spinner from '../../components/Spinner/Spinner';

const EditBasket = ({ licences }) => (
  <>
    <PageTitle>Edit purchase</PageTitle>
    {!licences && <Spinner fontSize="25px" />}
    <LicenceList licences={licences} />
    <OrderSummary />
    <div style={{ textAlign: 'right' }}>
      <Link to="/confirm-details">
        <button type="button">Update purchase</button>
      </Link>
    </div>
  </>
);

EditBasket.propTypes = {
  licences: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      users: PropTypes.number,
    })
  ),
};

EditBasket.defaultProps = {
  licences: null,
};

const mapStateToProps = state => ({
  licences: state.totalLicences.licences,
});

export default connect(mapStateToProps)(EditBasket);
