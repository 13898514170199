import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { auth, getPassword, handleError } from '../../actions';
import history from '../../history';
import PageTitle from '../../components/PageTitle/PageTitle';
import CreateAccountForm from '../../components/Forms/CreateAccountForm';
import EmailValidation from './EmailValidation';
import Modal from '../../components/Modal';
import Spinner from '../../components/Spinner/Spinner';
import Mixpanel from '../../mixpanel';

class CreateAccount extends React.Component {
  constructor() {
    super();
    this.state = {
      loading: false,
    };
    this.onSubmitForm = this.onSubmitForm.bind(this);
    this.renderCorrectForm = this.renderCorrectForm.bind(this);
    this.renderError = this.renderError.bind(this);
  }

  componentDidMount() {
    const { registered, selectedLicences } = this.props;
    if (registered) history.goBack();
    else if (!selectedLicences) history.push('/');
    else Mixpanel.track('[PAGE] Create Account');
  }

  onSubmitForm(formValues) {
    const { auth } = this.props;
    auth(formValues, true);
  }

  renderCorrectForm() {
    const { zohoLead, userData } = this.props;
    if (!zohoLead) {
      return (
        <>
          <PageTitle>Create a VU.CITY account</PageTitle>
          <div className="subtitle">
            In order to download and access the VU.CITY Software, you’ll need to
            have an account. It’s free to set up and will only takes a few
            minutes.
            <br />
            Please start by filling in your details below.
          </div>

          <CreateAccountForm
            onSubmitForm={this.onSubmitForm}
            button="Create Account"
            disabled={false}
          />
        </>
      );
    }
    return (
      <EmailValidation
        onContinue={() => {
          this.onSubmitForm(userData);
        }}
      />
    );
  }

  renderError() {
    const { error, handleError } = this.props;
    const { loading } = this.state;
    if (error) {
      if (error === 'Email already registered at VU.CITY') {
        return (
          <Modal
            message="This e-mail has already been registered. Please contact the Sales Team if you need further assistance."
            hideModal={() => {
              this.setState({ loading: true });
              window.location = 'https://vu.city/contact-us/';
            }}
          >
            {loading ? <Spinner fontSize="18px" /> : 'Contact us'}
          </Modal>
        );
      }
      return (
        <Modal message={error} hideModal={handleError}>
          Try again
        </Modal>
      );
    }
    return false;
  }

  render() {
    return (
      <>
        {this.renderCorrectForm()}
        {this.renderError()}
      </>
    );
  }
}

CreateAccount.propTypes = {
  auth: PropTypes.func.isRequired,
  location: PropTypes.shape({
    pathName: PropTypes.string,
  }).isRequired,
  zohoLead: PropTypes.bool.isRequired,
  registered: PropTypes.bool,
  selectedLicences: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  handleError: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  userData: PropTypes.object,
};

CreateAccount.defaultProps = {
  registered: null,
  selectedLicences: null,
  error: null,
  userData: null,
};

const mapStateToProps = state => ({
  zohoLead: state.auth.zohoLead,
  error: state.auth.error,
  selectedLicences: state.totalLicences.selectedLicences,
  userData: state.auth.userData,
  registered: state.auth.registered,
});

export default connect(
  mapStateToProps,
  { auth, getPassword, handleError }
)(CreateAccount);
