import React from 'react';
import logo from '../../assets/img/vucity-logo.png';

const Logo = () => (
  <div style={{ textAlign: 'center' }}>
    <img
      style={{ width: '130px', height: 'auto' }}
      src={logo}
      alt="VU.CITY Logo"
    />
  </div>
);

export default Logo;
