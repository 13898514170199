import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classes from '../../../assets/css/LicenceItem.module.css';
import defaultImg from '../../../assets/img/2.png';

export const LicenceItem = props => {
  const { users, addLicence, removeLicence, name, imgSrc, squareM } = props;

  const licenceButtons = () => {
    if (!users) {
      return (
        <button
          type="button"
          data-test="addLicence"
          onClick={() => addLicence()}
        >
          Add
        </button>
      );
    }
    return (
      <div className={classes.AddLicence}>
        <button
          type="button"
          data-test="removeLicence"
          onClick={() => removeLicence()}
        >
          -
        </button>
        <div data-test="numLicences">{users}</div>
        <button
          type="button"
          data-test="addLicence"
          onClick={() => addLicence()}
        >
          +
        </button>
      </div>
    );
  };

  return (
    <div className={classes.LicenceItem} data-test="licenceItem">
      {!imgSrc ? (
        <img style={{ width: '100%' }} src={defaultImg} alt="LicenceImg" />
      ) : (
        <img style={{ width: '100%' }} src={imgSrc} alt="LicenceImg" />
      )}
      <p style={{ fontWeight: 'bold', fontSize: '1rem' }}>{name}</p>
      <p style={{ minHeight: '34px' }}>Sq km: {squareM}</p>
      {licenceButtons()}
    </div>
  );
};

LicenceItem.propTypes = {
  users: PropTypes.number,
  addLicence: PropTypes.func.isRequired,
  removeLicence: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  imgSrc: PropTypes.string,
  squareM: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

LicenceItem.defaultProps = {
  users: null,
  imgSrc: null,
  squareM: null,
};

export default connect()(LicenceItem);
