export default {
  REGISTER_ACCOUNT: process.env.REACT_APP_REGISTER_ACCOUNT_URL,
  SIGN_IN: process.env.REACT_APP_PAYMENT_DETAILS_LOGIN_URL,
  REGISTER_PASSWORD: process.env.REACT_APP_REGISTER_PASSWORD_URL,
  UPDATE_ACCOUNT: process.env.REACT_APP_UPDATE_ACCOUNT_URL,
  DASHBOARD: process.env.REACT_APP_LOGIN_USER_URL,
  GET_LICENSES: process.env.REACT_APP_GET_LICENSES_URL,
  CREATE_SUBSCRIPTION: process.env.REACT_APP_CREATE_SUBSCRIPTION_URL,
  STRIPE_KEY: process.env.REACT_APP_STRIPE_KEY,
  MIXPANEL_KEY: process.env.REACT_APP_MIXPANEL_KEY,
};
