import React from 'react';
import PropTypes from 'prop-types';
import PageTitle from '../../components/PageTitle/PageTitle';
import classes from '../../assets/css/EmailValidation.module.css';

const EmailValidation = ({ onContinue }) => (
  <>
    <PageTitle>We’ve just emailed you</PageTitle>
    <div className={classes.EmailValidation}>
      <p>
        Your email is on its way. If you don’t receive it, please check your
        spam folder.
      </p>
    </div>
    <div className={classes.ResendEmail}>
      <p>
        Still haven&apos;t received it?
        <br />
        <button onClick={onContinue} type="button">
          Resend email
        </button>
      </p>
    </div>
  </>
);

EmailValidation.propTypes = {
  onContinue: PropTypes.func.isRequired,
};

export default EmailValidation;
