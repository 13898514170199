import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { proceedToPayment, handleError } from '../../actions';
import history from '../../history';
import PageTitle from '../../components/PageTitle/PageTitle';
import CreateAccountForm from '../../components/Forms/CreateAccountForm';
import Modal from '../../components/Modal';
import Mixpanel from '../../mixpanel';

const ConfirmDetails = ({
  userData,
  proceedToPayment,
  termsAgreed,
  selectedLicences,
  token,
  totalPrice,
  handleError,
  error,
  updatedInfo,
}) => {
  useEffect(() => {
    if (selectedLicences === false) history.push('/sign-in');
    else if (token === null) history.push('/create-account');
    else if (!updatedInfo) Mixpanel.track('[PAGE] Confirm Details');
  }, [token, selectedLicences, updatedInfo]);

  const confirmDetails = formValues => {
    proceedToPayment(formValues);
    history.push('/purchase');
    // This is a hack to have the conditional rendering scroll to top
    // Can be fixed by making these individual components and routing them
    // eslint-disable-next-line no-restricted-globals
    scrollTo(0, 0);
  };

  const renderError = () => {
    if (error) {
      if (error === 'invalid sessionId') {
        return (
          <Modal
            message="Something went wrong. Please sign in again."
            hideModal={() => {
              history.push('/sign-in');
              handleError();
            }}
          >
            Sign in
          </Modal>
        );
      }
      return (
        <Modal message={error} hideModal={handleError}>
          Try again
        </Modal>
      );
    }
  };

  const renderContent = () => (
    <>
      <PageTitle>Confirm purchase</PageTitle>
      <div className="subtitle">
        Thank you for your order. Please confirm your details in the fields
        below to finalise your purchase.
      </div>
      <CreateAccountForm
        initialValues={userData}
        button="Proceed to payment"
        onSubmitForm={confirmDetails}
        disabled={!(termsAgreed && totalPrice)}
      />
      {renderError()}
    </>
  );

  return <>{renderContent()}</>;
};

ConfirmDetails.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  userData: PropTypes.object,
  proceedToPayment: PropTypes.func.isRequired,
  termsAgreed: PropTypes.bool.isRequired,
  selectedLicences: PropTypes.bool.isRequired,
  token: PropTypes.string,
  totalPrice: PropTypes.number.isRequired,
  handleError: PropTypes.func.isRequired,
  updatedInfo: PropTypes.bool.isRequired,
  error: PropTypes.bool,
};

ConfirmDetails.defaultProps = {
  userData: null,
  error: null,
  token: null,
};

const mapStateToProps = state => ({
  userData: state.auth.userData,
  termsAgreed: state.payment.termsAgreed,
  token: state.auth.token,
  totalPrice: state.totalLicences.totalPrice,
  selectedLicences: state.totalLicences.selectedLicences,
  error: state.auth.error,
  updatedInfo: state.auth.updatedInfo,
});

export default connect(
  mapStateToProps,
  { proceedToPayment, handleError }
)(ConfirmDetails);
