import axios from 'axios';
import * as actionTypes from './actionTypes';
import { updateState } from './chooseLicence';
import { setLicenceLength } from './licenceLength';
import history from '../history';
import Mixpanel from '../mixpanel';
import { default as env } from '../config';

export const authStart = () => ({
  type: actionTypes.AUTH_START,
});

export const authSuccess = (token, userId, data) => ({
  type: actionTypes.AUTH_SUCCESS,
  userId,
  userData: data,
  token,
});

export const authFailed = error => ({
  type: actionTypes.AUTH_FAILED,
  payload: error,
});

export const handleError = () => ({
  type: actionTypes.HANDLE_ERROR,
});

export const registerEmail = () => ({
  type: actionTypes.REGISTER_EMAIL,
});

export const getPassword = pass => dispatch => {
  dispatch({
    type: actionTypes.SET_PASSWORD,
    payload: pass,
  });
};

export const confirmDetails = () => ({
  type: actionTypes.CONFIRM_DETAILS,
});

export const auth = (formValues, signUp) => async (dispatch, getState) => {
  dispatch(authStart());

  const licencesArr = [];
  getState().totalLicences.licences.map(licence =>
    licence.users !== undefined && licence.users > 0
      ? licencesArr.push(licence)
      : licencesArr
  );

  if (signUp) {
    const data = {
      name: formValues.name,
      email: formValues.email,
      company: formValues.company,
      contactNumber:
        formValues.contactNumber === undefined ? '' : formValues.contactNumber,
      licenses: licencesArr,
      totalLicenses: getState().totalLicences.totalLicences,
      plan: getState().totalLicences.plan,
      subTotal: getState().totalLicences.subTotal,
      totalPrice: getState().totalLicences.totalPrice,
      // discountedPrice: getState().totalLicences.discountedPrice
    };
    try {
      const response = await axios.post(env.REGISTER_ACCOUNT, data);

      if (response.data.error) {
        Mixpanel.track('[ACTION] Unsuccessful sign up');
        dispatch(authFailed(response.data.error));
      } else {
        Mixpanel.alias(data.email);
        Mixpanel.track('[ACTION] Successful sign up', {
          'Licence plan': data.plan,
          'Total Licences': data.totalLicences,
        });
        Mixpanel.people.set({
          $name: data.name,
          $email: data.email,
          $phone: data.contactNumber,
          $created: new Date().toISOString(),
          'Licence plan': data.plan,
          'Total price': data.totalPrice,
        });

        dispatch(authSuccess(response.data.link, data.email, data));
      }
    } catch (e) {
      if (
        e.response !== undefined &&
        e.response.data.error &&
        e.request.status === 400
      ) {
        Mixpanel.track('[ACTION] Unsuccessful sign up');
        dispatch(authFailed(e.response.data.error));
      } else {
        Mixpanel.track('[ACTION] Unsuccessful sign up');
        dispatch(authFailed('There seems to be a problem. Please try again.'));
      }
    }
  } else {
    const data = {
      email: formValues.email,
      password: formValues.password,
    };

    try {
      const response = await axios.post(env.SIGN_IN, data);
      // eslint-disable-next-line camelcase
      const { _payment_detail_login } = response.data;
      if (_payment_detail_login.error) {
        Mixpanel.track('[ACTION] Unsuccessful sign in');
        dispatch(authFailed(_payment_detail_login.error));
      } else {
        Mixpanel.identify(data.email);
        Mixpanel.track('[ACTION] Successful sign in');

        dispatch(setLicenceLength(_payment_detail_login.plan));
        dispatch(updateState(_payment_detail_login));
        dispatch(getPassword(_payment_detail_login.sessionId));
        dispatch(
          authSuccess(
            _payment_detail_login.email,
            _payment_detail_login.sessionId,
            _payment_detail_login
          )
        );
        history.push('/confirm-details');
      }
    } catch (e) {
      Mixpanel.track('[ACTION] Unsuccessful sign in');

      dispatch(authFailed('There seems to be an error. Please try again.'));
    }
  }
};

export const setPassword = (pass, regId) => async dispatch => {
  dispatch(authStart());

  const data = {
    password: pass,
    regId,
  };

  try {
    const response = await axios.post(env.REGISTER_PASSWORD, data);
    if (response.data.error) {
      Mixpanel.track('[ACTION] Set password failed');

      dispatch(authFailed(response.data.error));
    } else {
      Mixpanel.identify(response.data.email);
      Mixpanel.track('[ACTION] Set password');

      dispatch(setLicenceLength(response.data.plan));
      dispatch(updateState(response.data));
      dispatch(getPassword(response.data.sessionId));
      dispatch(
        authSuccess(response.data.email, response.data.sessionId, response.data)
      );
      history.push('/confirm-details');
    }
  } catch (e) {
    Mixpanel.track('[ACTION] Set password failed');

    dispatch(
      authFailed(
        'There seems to be a problem. Please try again or type another password.'
      )
    );
  }
};

export const proceedToPayment = formValues => async (dispatch, getState) => {
  dispatch(authStart());

  const licencesArr = [];
  getState().totalLicences.licences.map(licence =>
    licence.users !== undefined && licence.users > 0
      ? licencesArr.push(licence)
      : licencesArr
  );

  const data = {
    name: formValues.name,
    email: formValues.email,
    company: formValues.company,
    contactNumber:
      formValues.contactNumber === undefined ? '' : formValues.contactNumber,
    industry: formValues.industry,
    licenses: licencesArr,
    totalLicenses: getState().totalLicences.totalLicences,
    plan: getState().totalLicences.plan,
    subTotal: getState().totalLicences.subTotal,
    totalPrice: getState().totalLicences.totalPrice,
    sessionId: getState().auth.token,
  };

  try {
    const response = await axios.patch(env.UPDATE_ACCOUNT, data);
    // eslint-disable-next-line camelcase
    const { _register_payment_detail } = response.data.rows[0];
    if (_register_payment_detail.error) {
      Mixpanel.track('[ACTION] Confirmed details failed');
      dispatch(authFailed(_register_payment_detail.error));
    } else {
      Mixpanel.identify(data.email);
      Mixpanel.track('[ACTION] Confirmed details', {
        'Licence plan': data.plan,
        'Total Licences': data.totalLicences,
      });

      dispatch(authSuccess(data.sessionId, data.email, data));
      dispatch(confirmDetails());
    }
  } catch (e) {
    Mixpanel.track('[ACTION] Confirmed details failed');
    dispatch(authFailed('There seems to be a problem. Please try again.'));
  }
};

export const redirectToDashboard = () => async (dispatch, getState) => {
  dispatch(authStart());

  const data = {
    sessionId: getState().auth.token,
  };
  try {
    const response = await axios.post(env.DASHBOARD, data);

    if (response.data.error) {
      dispatch(authFailed(response.data.error));
    } else {
      Mixpanel.identify(getState().auth.userData.email);
      Mixpanel.track('[ACTION] Accessed dashboard');

      const encodedIdent = encodeURIComponent(response.data.ident);
      window.location.href = `https://dashboard.vu.city/dashboard/login?ident=${encodedIdent}`;
    }
  } catch (e) {
    dispatch(authFailed('There seems to be a problem. Please try again.'));
  }
};
