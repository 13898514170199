import React from 'react';
import classes from '../../../assets/css/Prefooter.module.css';

const Prefooter = () => (
  <div className={classes.Prefooter}>
    <div className={classes.PrefooterContainer}>
      <div className={classes.Column}>
        <a
          href="https://goo.gl/maps/gaDF8nhRB7LGD9Yv9"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="fas fa-map-marker-alt" />
          <p>Address</p>
          <div>
            The Whitehouse, Belvedere Road
            <br />
            London SE1 8GA
          </div>
        </a>
      </div>
      <div className={classes.Column}>
        <a href="mailto:info@vu.city?subject=VUCITY">
          <i className="fas fa-envelope" />
          <p>Email</p>
          <div>info@vu.city</div>
        </a>
      </div>
      <div className={classes.Column}>
        <a href="tel:+442038897030">
          <i className="fas fa-phone" />
          <p>Phone</p>
          <div>+44 (0)203 889 7030</div>
        </a>
      </div>
    </div>
  </div>
);

export default Prefooter;
