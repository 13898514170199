import React from 'react';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import history from '../../history';

const ConditionRoute = ({ component: Component, condition, ...rest }) => (
  <Route
    {...rest}
    render={props => (condition ? <Component {...props} /> : history.goBack())}
  />
);

ConditionRoute.propTypes = {
  // PropTypes doesn't have a check for components, so casting to an object
  // eslint-disable-next-line react/forbid-prop-types
  component: PropTypes.object.isRequired,
  condition: PropTypes.bool.isRequired,
};

// PropTypes.

export default ConditionRoute;
