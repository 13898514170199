import React from 'react';
import classes from '../../assets/css/Footer.module.css';

const Footer = () => (
  <div className={classes.Footer}>
    <div className={classes.FooterContainer}>
      <div className={classes.Column}>
        <p>
          &#9400; {new Date().getFullYear()} All rights reserved Vucity Limited.
        </p>
      </div>
      <div className={classes.Column}>
        <p style={{ lineHeight: '1.5' }}>
          <a
            href="https://vu.city/privacy/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy policy | Cookie policy
          </a>
          <br />
          Terms and conditions for:{' '}
          <a
            href="https://vu.city/wp-content/uploads/2018/03/VUCITY-Terms-and-Conditions-2018.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            Private sector
          </a>{' '}
          |{' '}
          <a
            href="https://vu.city/wp-content/uploads/2018/11/VUCITY-Terms-and-Conditions-2018-public-sector-v1.0.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            Public sector
          </a>
        </p>
      </div>
      <div className={classes.Column}>
        <a
          href="https://www.linkedin.com/company/vucity"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="fab fa-linkedin-in " title="vu city on linkedin" />
        </a>
        <a
          href="https://twitter.com/VUCITY_"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="fab fa-twitter" title="vu city on twitter" />
        </a>
        <a
          href="https://www.instagram.com/vucity_/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="fab fa-instagram" title="vu city on instagram" />
        </a>
        <a
          href="https://facebook.com/VUCITY1/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="fab fa-facebook-f" title="vu city on facebook" />
        </a>
      </div>
    </div>
  </div>
);

export default Footer;
