import React from 'react';
import PropTypes from 'prop-types';
import classes from '../../assets/css/PageTitle.module.css';

const PageTitle = ({ children }) => (
  <h1 className={classes.PageTitle}>{children}</h1>
);

PageTitle.propTypes = {
  children: PropTypes.node,
};

PageTitle.defaultProps = {
  children: null,
};

export default PageTitle;
