import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import licenceLengthReducer from './licenceLengthReducer';
import chooseLicenceReducer from './chooseLicenceReducer';
import authReducer from './authenticateReducer';
import paymentReducer from './paymentReducer';

export default combineReducers({
  plan: licenceLengthReducer,
  totalLicences: chooseLicenceReducer,
  auth: authReducer,
  payment: paymentReducer,
  form: formReducer,
});
