import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { VAT_THRESHOLD } from '../../reducers/chooseLicenceReducer';
import classes from '../../assets/css/OrderSummary.module.css';
import Spinner from '../Spinner/Spinner';

export const OrderSummary = props => {
  const {
    licences,
    plan,
    subTotal,
    totalPrice,
    token,
    userId,
    location,
  } = props;

  const { planValue, annualPrice, monthlyPrice } = plan;

  const renderOrderSummary = () => {
    if (!licences) {
      return <Spinner fontSize="30px" />;
    }

    const calculateLicenceTotal = () => {
      const licencePrice =
        planValue === 'annual'
          ? parseInt(annualPrice, 10)
          : parseInt(monthlyPrice, 10);
      return licencePrice;
    };

    const formatPrice = price => price.toLocaleString();

    const renderEditButton = () => (
      <div className={classes.Edit}>
        {token && location && (
          <div>
            <Link to={`/editPurchase/${userId}`}>Edit</Link>
          </div>
        )}
      </div>
    );

    const tableRow = licences.map(licence =>
      licence.users && licence.users > 0 ? (
        <tr key={licence.id}>
          <td>{licence.name}</td>
          <td>{licence.users}</td>
          <td>
            {planValue === 'annual'
              ? `£${formatPrice(annualPrice)}`
              : `£${formatPrice(monthlyPrice)}`}
          </td>
          <td>
            {`£${formatPrice(
              parseInt(licence.users, 10) * calculateLicenceTotal()
            )}`}
          </td>
        </tr>
      ) : null
    );

    if (totalPrice > 0) {
      return (
        <>
          <div className={classes.OrderSummaryContainer}>
            <div className={classes.Title}>Your order</div>
            <table width="100%" className={classes.OrderSummary}>
              <thead>
                <tr>
                  <th>City</th>
                  <th>Number of users</th>
                  <th>
                    Cost/
                    {planValue === 'annual' ? 'annum ' : 'month '}
                    per user
                  </th>
                  <th>Licence total</th>
                </tr>
              </thead>
              <tbody>{tableRow}</tbody>
              <tfoot>
                <tr className={classes.Total} rowSpan="2">
                  <td />
                  <td />
                  <td>Subtotal</td>
                  <td>{`£${formatPrice(subTotal)}`}</td>
                </tr>
                <tr rowSpan="2">
                  <td />
                  <td />
                  <td>{`${VAT_THRESHOLD * 100}% VAT`}</td>
                  <td>{`£${formatPrice(subTotal * VAT_THRESHOLD)}`}</td>
                </tr>
                <tr className={classes.Total} rowSpan="2">
                  <td />
                  <td />
                  <td>Total</td>
                  <td>{`£${formatPrice(totalPrice)}`}</td>
                </tr>
              </tfoot>
            </table>
            <div className={classes.Disclaimer}>
              {!token && (
                <div>
                  You can amend this at the end of the checkout process.
                </div>
              )}
            </div>
            {renderEditButton()}
          </div>
        </>
      );
    }

    if (token && totalPrice === 0) {
      return (
        <>
          <div className={classes.OrderSummaryContainer}>
            <div className={classes.Title}>Your order</div>
            <table width="100%" className={classes.OrderSummary}>
              <tbody>
                <tr>
                  <td>
                    In order to check out, you need to select what licences
                    you&apos;d like for the above cities.
                  </td>
                </tr>
              </tbody>
            </table>
            {renderEditButton()}
          </div>
        </>
      );
    }
  };

  return <>{renderOrderSummary()}</>;
};

OrderSummary.propTypes = {
  licences: PropTypes.arrayOf(PropTypes.object),
  plan: PropTypes.shape({
    planValue: PropTypes.string,
    annualPrice: PropTypes.number,
    monthlyPrice: PropTypes.number,
    discountedPrice: PropTypes.number,
  }).isRequired,
  subTotal: PropTypes.number.isRequired,
  totalPrice: PropTypes.number.isRequired,
  token: PropTypes.bool.isRequired,
  userId: PropTypes.string,
  location: PropTypes.string,
};

OrderSummary.defaultProps = {
  licences: null,
  userId: null,
  location: null,
};

const mapStateToProps = state => ({
  plan: state.plan,
  subTotal: state.totalLicences.subTotal,
  licences: state.totalLicences.licences,
  totalPrice: state.totalLicences.totalPrice,
  totalLicences: state.totalLicences.totalLicences,
  discountedPrice: state.totalLicences.discountedPrice,
  token: state.auth.token !== null,
  userId: state.auth.userId,
});

export default connect(mapStateToProps)(OrderSummary);
