/* eslint-disable react/jsx-indent */
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { redirectToDashboard } from '../../actions';
import history from '../../history';
import PageTitle from '../../components/PageTitle/PageTitle';
import Mixpanel from '../../mixpanel';

const Download = ({ paidOrder, invoice, redirectToDashboard }) => {
  useEffect(() => {
    if (paidOrder) Mixpanel.track('[PAGE] Continue to dashboard');
  });

  if (!paidOrder) {
    history.push('/confirm-details');
    return <></>;
  }
  return (
    <>
      <PageTitle>Congratulations!</PageTitle>
      <div className="subtitle">Your payment has been successful.</div>
      <div className="subtitle">
        {invoice !== null ? (
          <span>
            Your invoice number is: {invoice.invoiceNumber} and you can access
            your account any time from now.
          </span>
        ) : (
          ''
        )}
      </div>

      <div style={{ textAlign: 'center', margin: '60px 0' }}>
        <button type="button" onClick={redirectToDashboard}>
          Continue to your VU.CITY dashboard
        </button>
      </div>
    </>
  );
};

Download.propTypes = {
  paidOrder: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  invoice: PropTypes.object,
  redirectToDashboard: PropTypes.func.isRequired,
};

Download.defaultProps = {
  paidOrder: false,
  invoice: null,
};

const mapStateToProps = state => ({
  paidOrder: state.payment.paidOrder,
  invoice: state.payment.orderSummary,
  token: state.auth.token,
});

export default connect(
  mapStateToProps,
  { redirectToDashboard }
)(Download);
