import * as React from 'react';
import { withRouter } from 'react-router-dom';

const ScrollToTop = ({ location: { pathname }, children }) => {
  // eslint-disable-next-line no-restricted-globals
  React.useEffect(() => scrollTo(0, 0), [pathname]);
  return children;
};

export default withRouter(ScrollToTop);
